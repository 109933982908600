.monday-breadcrumb > ol li > span > span {
  @apply !mx-0 text-xs leading-4 font-normal;
}

button svg[data-testId="icon"] {
  @apply w-[16px] h-[16px] stroke-[3];
}

.monday-modal-hide-close-btn div:first-child[data-testid="modal-header_undefined-title"] {
  @apply hidden;
}
.monday-style-dialog-content-wrapper[data-testid="tooltip"] {
  @apply text-sm;
}

.zIndex-high{
  z-index: 999999999999999;
}