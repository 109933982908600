@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
	min-width: 768px;
	padding: 0;
	margin: 0;
	overflow: hidden;
}

@layer base {
	html {
		font-family: var(--font-family) !important;
	}
	h1,
	h2,
	h3 {
		font-family: var(--title-font-family) !important;
	}
}

:root {
	--font-family: Poppins;
	/* Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic,
    Noto Sans JP, sans-serif; */
	--title-font-family: Poppins;
	/* Roboto, Noto Sans Hebrew, Noto Kufi Arabic,
    Noto Sans JP, sans-serif; */
	--primary-color: #258750;
	--primary-hover-color: #007038;
	--primary-selected-color: #39a75d80;
	/* 
  --primary-selected-hover-color: #aed4fc;
  --primary-highlighted-color: #f0f7ff; */
}

/* FONT STYLES */

.poppins-thin {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: italic;
}
